import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { createPopper } from '@popperjs/core';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tailwindConfig from '@/tailwind.config';

const PopoverMenu = forwardRef(function PopoverMenu(
  {
    title,
    open,
    placement,
    onOpen,
    onClose,
    children,
    className,
    titleClass,
    mobileRelative,
    contentPlacement = 'top',
    disableCloseOnClickOutside,
    ...props
  },
  ref,
) {
  const [_open, set_open] = useState();
  const wrapper = useRef();
  const button = useRef();
  const popover = useRef();
  const popper = useRef();

  useImperativeHandle(ref, () => {
    return {
      open() {
        set_open(true);
      },
      close() {
        set_open(false);
      },
    };
  });

  useEffect(() => {
    setOpenValue(open);
  }, [open]);

  const showPopperMenu = () => {
    return !mobileRelative || (mobileRelative && window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10));
  };

  useEffect(() => {
    if (_open) {
      if (!popper.current) {
        if (showPopperMenu()) {
          popper.current = createPopper(button.current, popover.current, {
            placement: contentPlacement,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ],
          });
        }
      }

      setTimeout(() => {
        window.addEventListener('mouseup', closeIfClickedOutside);
      }, 0);

      onOpen && onOpen();
    } else {
      window.removeEventListener('mouseup', closeIfClickedOutside);
      onClose && onClose();
    }
    return () => {
      window.removeEventListener('mouseup', closeIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_open]);

  const closeIfClickedOutside = (e) => {
    let inside = false;
    e.composedPath().forEach((element) => {
      if (wrapper.current === element) {
        inside = true;
      }
    });

    if (!disableCloseOnClickOutside && !inside) {
      set_open(false);
    }
  };

  const setOpenValue = (newValue) => {
    set_open(newValue);
  };

  return (
    <div ref={wrapper} className={classNames('relative', _open && 'open', className)} {...props}>
      <button
        ref={button}
        type="button"
        className={classNames(
          'main-button flex items-center w-full justify-between px-4 py-3',
          _open && (placement === 'Top' ? 'border-t-transparent' : 'border-b-transparent'),
        )}
        onClick={() => setOpenValue(!_open)}
      >
        <span
          className={classNames('popover-title', titleClass)}
          dangerouslySetInnerHTML={{
            __html: title?.replace ? title.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') : title,
          }}
        />
        <span className="arrow ml-auto">
          <DropdownArrow
            role="presentation"
            className={classNames(
              'fill-black/75 transition',
              _open
                ? placement === 'Top'
                  ? 'rotate-180'
                  : '-rotate-180'
                : placement === 'Top'
                  ? '-rotate-0'
                  : 'rotate-0',
            )}
          />
        </span>
      </button>
      <div
        ref={popover}
        className={`content-container w-full z-10 ${_open ? `content-container-open ${!showPopperMenu() ? '!relative' : ''}` : ''} `}
      >
        <div
          className={classNames(
            'content left-0 w-full custom-scroll-bar bg-primary rounded-[16px] border transform  focus:outline-none transition-opacity',
            _open ? 'flex opacity-100 scale-100' : 'hidden opacity-0 scale-0',
          )}
          tabIndex="-1"
        >
          {children}
        </div>
      </div>
    </div>
  );
});

PopoverMenu.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  placement: PropTypes.oneOf(['Top', 'Bottom']),
  contentPlacement: PropTypes.string,
};

export default PopoverMenu;
