import React, { useState } from 'react';
import { ModuleBase, HeadingTag, Button } from '@/components';
import Plus from '@/assets/plus2.svg';
import Minus from '@/assets/minus2.svg';
import classnames from 'classnames';
import { isColorDark, themeFromBg } from '@/utils';
import classNames from 'classnames';
import { isIOS, isSafari } from 'react-device-detect';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const AccordionModule = ({ data }) => {
  const { headingTitle, allowOpeningMultiple, accordionElements, primaryCTA, backgroundColour } = data || {};
  const [activeIndexList, setActiveIndexList] = useState([]);

  const toggle = (index) => {
    let newArray = [...activeIndexList];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      if (allowOpeningMultiple) {
        newArray = [index, ...newArray];
      } else {
        newArray = [index];
      }
    }
    setActiveIndexList(newArray);
  };

  const faqData = () => {
    return accordionElements;
  };

  return (
    <ModuleBase data={data}>
      <FadeUpStarter className="container lg:pl-[186px] lg:pr-[156px]">
        {headingTitle && (
          <FadeUpAnimation>
            <HeadingTag
              data={headingTitle}
              className="font-ivy-ora text-center text-[56px] font-normal leading-[60px] -tracking-normal color-from-bg lg:text-[80px] lg:leading-[72px] lg:-tracking-ultraWide"
            />
          </FadeUpAnimation>
        )}

        {accordionElements && (
          <div className="mt-6 lg:mt-8">
            {faqData().map((element, index) => (
              <FadeUpAnimation key={index} className="first:pt-0 first:md:pt-4">
                <button
                  type="button"
                  className={`relative flex items-center justify-between w-full text-left font-montserrat text-base leading-6.5 pl-[47px] lg:pl-[86px]
                  transition-all duration-500  
                  ${activeIndexList.includes(index) ? 'opacity-1' : 'opacity-50'}
                  `}
                  onClick={() => toggle(index)}
                >
                  <div className="">
                    <Plus
                      role="presentation"
                      className={classnames(
                        'scale-[0.65] lg:scale-100 lg:w-[19px]  lg:h-[19px]  transition-transform duration-200 color-from-bg absolute left-0 -translate-y-1/2',
                        isIOS && isSafari ? '!top-[43%]' : 'top-[41%]',
                        activeIndexList.includes(index)
                          ? '-rotate-90 opacity-0 top-[45%]'
                          : `rotate-90 opacity-1 ${isIOS && isSafari ? '!top-[43%]' : 'top-[41%]'}`,
                      )}
                    />
                  </div>
                  <div className="">
                    <Minus
                      role="presentation"
                      className={classnames(
                        'scale-[0.65] lg:scale-100 lg:w-[19px]  lg:h-[19px]  transition-transform duration-200 color-from-bg absolute left-0',
                        isIOS && isSafari ? '!top-[50%]' : 'top-[50%]',
                        activeIndexList.includes(index)
                          ? 'opacity-1 top-[45%]'
                          : `  opacity-0 ${isIOS && isSafari ? '!top-[50%]' : 'top-[50%]'}`,
                      )}
                    />
                  </div>

                  <div
                    className={`flex justify-between items-center w-full  pt-6  transition-all duration-500 
                    ${activeIndexList.includes(index) ? 'pb-4 ' : '  pb-10 '}
                    `}
                  >
                    <span
                      className={classnames(
                        activeIndexList.includes(index) ? '' : ' delay-300',
                        'transition-all duration-100',
                        'color-from-bg font-ivy-ora text-[24px] font-normal leading-8 -tracking-wider',
                      )}
                    >
                      {element.title}
                    </span>

                    <div>
                      <Button
                        theme={themeFromBg(isColorDark(backgroundColour))}
                        link={element.link}
                        type="tertiary"
                        className={`hidden lg:block transition-all duration-500
                                ${activeIndexList.includes(index) ? 'opacity-1' : 'opacity-0'}
                    `}
                      ></Button>
                    </div>
                    {/* <pre>{JSON.stringify(element.link, null, 2)}</pre> */}
                  </div>
                </button>

                <div
                  className={classNames(
                    'h-[1px] transition-all duration-1000 ml-[47px] lg:ml-[86px]',
                    activeIndexList.includes(index) ? 'opacity-0' : 'opacity-1',
                    isColorDark(backgroundColour) ? 'bg-white/10' : 'bg-black/10',
                  )}
                ></div>

                <div
                  className={classnames(
                    'transition-all overflow-hidden duration-1000 ml-[47px] lg:ml-[86px]',
                    activeIndexList.includes(index)
                      ? `max-h-screen opacity-1 ${!allowOpeningMultiple ? 'delay-500' : ''}`
                      : 'max-h-0 pt-0 opacity-50 ',
                  )}
                >
                  {element.text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: element.text }}
                      className="font-montserrat color-from-bg text-sm leading-6 font-normal  mb-10 [&_a]:underline [&_a]:underline-offset-4"
                    />
                  )}
                  <div>
                    <Button
                      theme={themeFromBg(isColorDark(backgroundColour))}
                      link={element.link}
                      type="tertiary"
                      className={classnames('block lg:hidden w-fit mb-8')}
                    ></Button>
                  </div>

                  <div
                    className={classNames(
                      'h-[1px] transition-all duration-1000 ',
                      activeIndexList.includes(index) ? 'opacity-1' : 'opacity-0',
                      isColorDark(backgroundColour) ? 'bg-white/10' : 'bg-black/10',
                    )}
                  ></div>
                </div>
              </FadeUpAnimation>
            ))}
          </div>
        )}

        {primaryCTA && (
          <FadeUpAnimation className="flex justify-center mt-10">
            <div className="group">
              <Button theme={themeFromBg(isColorDark(backgroundColour))} link={primaryCTA} type={'primary'}></Button>
            </div>
          </FadeUpAnimation>
        )}
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default AccordionModule;
