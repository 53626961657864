import React, { useEffect, useState } from 'react';
import { useScrollerMotion } from 'scroller-motion';
import { motion } from 'framer-motion';
import tailwindConfig from '@/tailwind.config';
import { createPortal } from 'react-dom';
import { AvailabilityForm, ModalPortal } from '@/components';

const BookingBarModuleForDesktop = ({ data }) => {
  const { scrollY } = useScrollerMotion();
  const [scrollTop, setScrollTop] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [relative, setRelative] = useState(false);

  const [selectedData, setSelectedData] = useState({
    hotel: data?.currentHotel?.bookingCode || null,
    startDate: null,
    endDate: null,
    adults: 2,
    children: null,
  });

  useEffect(() => {
    setLoaded(true);
    document.querySelector('.page-container-content').classList.add('bg-secondary-coolsand');
    let distanceFromTop = 5000;
    const loaded = () => {
      if (!document.querySelector('.relative-booking-bar')) return;
      const relativeBookingBar = document.querySelector('.relative-booking-bar').offsetTop - window.innerHeight + 72;
      setTimeout(() => {
        distanceFromTop = relativeBookingBar;
        setShowBar(true);
      }, 100);
    };

    scrollY.onChange((value) => {
      const scrollTop = value;
      if (scrollTop > distanceFromTop) {
        setRelative(true);
      } else {
        setRelative(false);
      }
    });
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollTop(scrollTop);
    };

    const resizeObserver = new ResizeObserver(() => {
      loaded();
    });
    resizeObserver.observe(document.documentElement);

    window.addEventListener('load', loaded);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', loaded);
      resizeObserver.disconnect();
    };
  }, [scrollY]);
  return (
    loaded && (
      <>
        <motion.div
          className={`booking-bar h-0 w-full z-50 bottom-0 
          ${relative ? 'opacity-0' : 'opacity-100'}
          ${navigator.userAgent.indexOf('Firefox') > 0 || /.*Version\/(1[0-4]|[5-9])(\.\d+)* Safari.*/.test(navigator.userAgent) ? 'absolute' : 'sticky'}
          `}
          style={{
            y: scrollY,
            top: scrollTop > 0 ? 'calc(100vh - 72px)' : 'calc(100vh - 72px - 10px)',
            transition: 'margin .5s linear, padding .5s linear, top .5s linear, opacity .3s linear',
          }}
        >
          <div
            style={{ transition: 'opacity .5s linear, width .5s linear' }}
            className={`[&_.content]:h-[72px] bg-secondary-coolsand rounded m-auto ${scrollTop > 0 ? 'w-full' : 'lg:w-[1060px] xxl:w-[1360px]'} 
            ${!showBar ? 'opacity-0' : 'opacity-100'}
            `}
          >
            <BookingBarContent data={data} selectedData={selectedData} setSelectedData={setSelectedData} />
          </div>
        </motion.div>
        {createPortal(
          <div
            className={`relative-booking-bar booking-bar [&_.content]:h-[72px] bg-secondary-coolsand rounded m-auto  transition-width duration-500 ${scrollTop > 0 ? 'w-full' : 'lg:w-[1060px] xxl:w-[1360px]'} `}
          >
            {/* <BookingBarContent data={data} selectedData={selectedData} setSelectedData={setSelectedData} /> */}
          </div>,
          document.querySelector('.page-container-content'),
        )}
      </>
    )
  );
};

const BookingBarModuleForMobile = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedData, setSelectedData] = useState({
    hotel: data?.currentHotel?.bookingCode || null,
    startDate: null,
    endDate: null,
    adults: 2,
    children: null,
  });

  useEffect(() => {
    if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
      setTimeout(() => {
        document.querySelector('.page-container-content').classList.add('flex', 'flex-col');
        const sections = document.querySelectorAll('.page-container-content section');
        for (var i = 0; i < sections.length; ++i) {
          const order = Array.from(sections[i].classList).some((className) => className.includes('Hero'))
            ? 'order-0'
            : 'order-2';
          sections[i].classList.add(order);
        }
      });
    }
    setLoaded(true);
  }, []);

  return (
    loaded &&
    createPortal(
      <div
        className={`
        order-1
        [&_.content]:flex-col 
        booking-bar z-50 w-full relative bottom-0 transition-opacity bg-secondary-coolsand rounded duration-500 `}
      >
        {data?.currentHotel?.bookingDirectMessage && (
          <ModalPortal className={'lg:hidden'}>
            <div
              className="fixed w-max max-w-[90%] text-primary bottom-[4dvh] md:bottom-[6dvh] transition-top duration-1000 -translate-x-1/2 left-1/2 md:-translate-x-0 md:left-[unset] md:right-10 bg-highlight-sunshineYellow py-1  rounded-[48px] border border-primary/10 text-paragraph-s not-italic font-normal leading-5 [&_a]:py-2 [&_p]:px-6"
              dangerouslySetInnerHTML={{ __html: data?.currentHotel?.bookingDirectMessage }}
            />
          </ModalPortal>
        )}

        <BookingBarContent data={data} selectedData={selectedData} setSelectedData={setSelectedData} />
      </div>,
      document.querySelector('.page-container-content'),
    )
  );
};

const BookingBarContent = ({ data, selectedData, setSelectedData }) => {
  return (
    <div
      className={`content relative flex justify-center m-auto items-center gap-2 p-2 transition-opacity duration-500 w-full lg:max-w[1024px] xxl:max-w-[1360px] text-primary [&_path]:!fill-primary
      [&_.form-item]:w-[353px] lg:[&_.form-item]:w-[200px] xxl:[&_.form-item]:w-[262px] [&_.form-item_button]:h-[56px] [&_.dropdownPlaceholder]:hidden
       [&_.form-item>div]:bg-[#eae6e2]  [&_.form-item>div]:w-full [&_.form-item_.input-group>div]:w-full
      [&_.search-container>a]:!text-primary
      `}
    >
      {data?.currentHotel?.bookingDirectMessage && (
        <div
          className="absolute hidden lg:block lg:left-2 w-fit md:left-[unset] md:right-10 text-primary bottom-[84px] bg-highlight-sunshineYellow   rounded-[48px] border border-primary/10 text-paragraph-s not-italic font-normal py-1 leading-5 [&_a]:py-2 [&_p]:px-6"
          dangerouslySetInnerHTML={{ __html: data?.currentHotel?.bookingDirectMessage }}
        />
      )}

      <AvailabilityForm
        availabilityData={data}
        searchClass="secondary"
        placement="Top"
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
    </div>
  );
};
const BookingBarModule = ({ data }) => {
  const screens = tailwindConfig.theme.screens;
  const [clientOnlyData, setClientOnlyData] = useState(null);
  useEffect(() => {
    setClientOnlyData(true);
  }, []);

  return (
    clientOnlyData &&
    (window.innerWidth < parseInt(screens.lg) ? (
      <BookingBarModuleForMobile data={data} />
    ) : (
      <BookingBarModuleForDesktop data={data} />
    ))
  );
};
export default BookingBarModule;
