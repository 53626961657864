import React, { useState, useRef, useEffect } from 'react';
import { FormControl, PopoverMenu } from '@/components';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';

const AvailabilityForm = ({ availabilityData, searchClass, placement, selectedData, setSelectedData }) => {
  const [hotel, setHotel] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [minDate, setMinDate] = useState(null);
  const [promocode, setPromocode] = useState(null);
  const [promocodeApplied, setPromocodeApplied] = useState(false);

  const router = useRouter();
  const { t } = useTranslation('common');

  const datepicker = useRef();
  const guests = useRef();
  const promocodeInput = useRef();
  const promoCodePopoverMenu = useRef();

  const _setHotel = (event) => {
    setHotel(event.target.value);
    setSelectedData({ ...selectedData, hotel: event.target.value });
    datepicker.current.open();
  };
  const setDates = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedData({ ...selectedData, startDate: newStartDate, endDate: newEndDate });
    if (newStartDate && newEndDate) {
      guests.current.open();
    }
  };
  const _setAdults = (event) => {
    setAdults(event.target.value);
    setSelectedData({ ...selectedData, adults: event.target.value });
  };
  const _setChildren = (event) => {
    setChildren(event.target.value);
    setSelectedData({ ...selectedData, children: event.target.value });
  };

  const guestsapply = () => {
    guests.current.close();
    promoCodePopoverMenu.current?.open();
    promocodeInput.current.focus();
  };

  const applyPromocode = (e) => {
    e.preventDefault();
    if (promocode) {
      setPromocodeApplied(!promocodeApplied);
    }
  };

  useEffect(() => {
    if (selectedData.hotel !== hotel) {
      setHotel(selectedData.hotel);
    }
    if (selectedData.startDate !== startDate) {
      setStartDate(selectedData.startDate);
    }
    if (selectedData.endDate !== endDate) {
      setEndDate(selectedData.endDate);
    }
    if (selectedData.adults !== adults) {
      setAdults(selectedData.adults);
    }
    if (selectedData.children !== children) {
      setChildren(selectedData.children);
    }
  }, [selectedData, hotel, startDate, endDate, adults, children]);

  const formValid = !!hotel && !!startDate && !!endDate;

  const bookingUrl = useRef(null);

  const checkBookingSystem = () => {
    const selectedHotel = availabilityData.hotels.find((h) => h.bookingCode === hotel);

    if (selectedHotel) {
      const bookSystem = availabilityData?.bookingSystems?.find((b) => b.name === selectedHotel.bookingSystem);

      if (bookSystem) {
        const newMinDate = new Date(bookSystem.nextBookableDate);

        if (!minDate || newMinDate.getTime() !== minDate.getTime()) {
          setMinDate(newMinDate);
        }
        try {
          const rootUrl = bookSystem.rootUrl;
          if (!rootUrl) {
            console.error('bookSystem.rootUrl is undefined or empty');
            return;
          }

          let formattedUrl;
          try {
            const slash = rootUrl.endsWith('/') ? '' : '/';
            formattedUrl = new URL(rootUrl + slash);
          } catch (e) {
            console.error('Invalid root URL:', rootUrl);
            return;
          }

          formattedUrl.searchParams.set('locale', router.locale);

          if (hotel) {
            formattedUrl.searchParams.set(bookSystem.hotelParam, hotel);
          }
          if (startDate) {
            formattedUrl.searchParams.set(bookSystem.checkInParam, dayjs(startDate)?.format('YYYY-MM-DD'));
          }

          if (endDate) {
            formattedUrl.searchParams.set(bookSystem.checkOutParam, dayjs(endDate)?.format('YYYY-MM-DD'));
          }

          if (adults) {
            formattedUrl.searchParams.set(bookSystem.adultParam, adults);
          }
          if (children) {
            formattedUrl.searchParams.set(bookSystem.childParam, children);
          }

          if (promocode) {
            formattedUrl.searchParams.set(bookSystem.promoCode || 'promoCode', promocode);
          }

          bookingUrl.current = formattedUrl;
        } catch (e) {
          console.error('Error constructing URL:', e);
        }
      } else {
        console.error('Invalid booking system:', bookSystem);
      }
    }
  };

  checkBookingSystem();

  const promoCode = (
    <form className="flex flex-row gap-2 lg:gap-0" onSubmit={applyPromocode}>
      <FormControl
        type="text"
        inputRef={promocodeInput}
        className={classNames(
          promocodeApplied ? '!bg-[#eae6e2] !border-transparent' : '!bg-white/50 !border-primary/25',
          'input h-[44px] lg:h-[56px] placeholder:!text-primary/50 !p-4 border lg:border-r-0 ',
          '!font-medium',
          'placeholder:!opacity-100 placeholder:text-tag-m placeholder:font-medium placeholder:leading-[22px]',
          'disabled:!text-primary',
        )}
        value={promocode}
        onChange={(e) => setPromocode(e.target.value)}
        placeholder={t('booking.$enterpromocode')}
        disabled={promocodeApplied}
      />
      <button
        className={classNames(
          'px-6 border w-[103px] border-primary  text-tag-m not-italic font-medium leading-[22px] font-cera !h-auto lg:!h-[56px]',
          !promocode && 'opacity-50',
        )}
        onClick={applyPromocode}
      >
        {promocodeApplied ? t('general.$edit') : t('general.$apply')}
      </button>
    </form>
  );

  return (
    <>
      <div className="form-items-container flex flex-col lg:flex-row gap-1 [&_.main-button]:!h-12 [&_.input-group_.dropdown.open_.content]:!drop-shadow-[0_0_0_rgba(0,0,0,0)] lg:justify-start">
        <div className="w-full form-item hotel">
          <FormControl
            type="select"
            className={classNames(
              `dropdown [&_.main-button]:pt-[11px] [&_.main-button]:pb-[11px] [&_em]:not-italic 
              h-auto lg:h-[56px] [&_.main-button]:!h-[56px] [&_.main-button]:!border-b-0
              [&_.content_li]:bg-[#eae6e2] [&_.content_li>div]:text-primary [&_.content_li>div]:text-base [&_.content_ul]:bg-transparent [&_.content_ul]:rounded-none
              [&_.content_li>div]:flex [&_.content_li>div]:items-center [&_.content_li>div]:h-[48px] [&_.content_li>div]:px-6 [&_.content_li>div]:py-3
              [&_.content]:!h-[288px] [&_.content]:rounded-none 
              [&_.content]:overflow-y-auto 
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0] [&_.content]:bg-[#eae6e2]
              `,
              hotel && 'selected',
            )}
            value={hotel}
            onChange={_setHotel}
            displayEmpty={false}
            placeholder={t('booking.$selectHotel')}
            options={availabilityData?.hotels.map((item) => ({
              value: item.bookingCode,
              label: item.hotelName,
            }))}
            placement={placement}
            mobileRelative={true}
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item dates">
          <FormControl
            popoverRef={datepicker}
            type="datepicker"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(event) => setDates(event.target.value)}
            placeholder={t('booking.$checkInCheckOut')}
            minDate={minDate}
            className={classNames(
              `h-auto lg:h-[56px] [&_.content]:!h-[364px] [&_.main-button]:!h-[56px] [&_.main-button]:!border-b-0
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0] [&_.arrow]:!block`,
              startDate && endDate && 'selected',
            )}
            placement={placement}
            mobileRelative={true}
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item guests input-group mt-0 mb-0">
          <PopoverMenu
            title={t('booking.$xAdultsXChildren', { adults, children })}
            className={classNames(
              `h-auto lg:h-[56px] [&_.content]:!h-[167px] [&_.main-button]:!h-[56px] [&_.main-button]:!border-b-0 dropdown
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0] [&_.content]:bg-[#eae6e2]
              `,
              (adults !== 2 || children !== 0) && 'selected',
            )}
            placement={placement}
            ref={guests}
            mobileRelative={true}
          >
            <div className="p-3 w-full">
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="font-montserrat text-base not-italic font-normal leading-6 py-2.5">
                  {t('booking.$adults')}
                </div>
                <FormControl
                  type="number"
                  value={adults}
                  onChange={(event) => _setAdults(event)}
                  minValue={1}
                  maxValue={availabilityData?.maxAdults}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="py-2.5">
                  <div className="font-montserrat text-base not-italic font-normal leading-6">
                    {t('booking.$children')}
                    <div className="text-[12px] font-thin h-0">{t('booking.$agedUnder')}</div>
                  </div>
                </div>
                <FormControl
                  type="number"
                  value={children}
                  onChange={(event) => _setChildren(event)}
                  minValue={0}
                  maxValue={availabilityData?.maxChildren}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              <div className="apply" onClick={guestsapply}>
                {t('booking.$xAdultsXChildrenApply')}
              </div>
            </div>
          </PopoverMenu>
        </div>
        <div className="spacer" />
        <div className="w-full form-item guests input-group mt-0 [&_.textPlaceholder]:hidden flex flex-row [&_.input-group]:mb-0 ">
          <PopoverMenu
            ref={promoCodePopoverMenu}
            title={`
              <div class="flex gap-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.1379 9.47133C2.01292 9.34631 1.94271 9.17678 1.94271 9C1.94271 8.82322 2.01292 8.65369 2.1379 8.52867L8.47123 2.19533C8.59623 2.0703 8.76577 2.00004 8.94257 2H13.3332C13.51 2 13.6796 2.07024 13.8046 2.19526C13.9297 2.32029 13.9999 2.48986 13.9999 2.66667V7.05733C13.9999 7.23413 13.9296 7.40367 13.8046 7.52867L7.47123 13.862C7.34622 13.987 7.17668 14.0572 6.9999 14.0572C6.82313 14.0572 6.65359 13.987 6.52857 13.862L2.1379 9.47133ZM13.1999 7.002V2.8H8.9979L2.7979 9L6.9999 13.202L13.1999 7.002ZM10.6666 6.33333C10.4014 6.33333 10.147 6.22798 9.95946 6.04044C9.77192 5.8529 9.66657 5.59855 9.66657 5.33333C9.66657 5.06812 9.77192 4.81376 9.95946 4.62623C10.147 4.43869 10.4014 4.33333 10.6666 4.33333C10.9318 4.33333 11.1861 4.43869 11.3737 4.62623C11.5612 4.81376 11.6666 5.06812 11.6666 5.33333C11.6666 5.59855 11.5612 5.8529 11.3737 6.04044C11.1861 6.22798 10.9318 6.33333 10.6666 6.33333Z"
                    fill="#081B36"
                  />
                </svg>
                ${t('booking.$haveacode')}
              </div>`}
            className={classNames(
              'lg:hidden !bg-transparent [&_.content-container]:!bg-transparent [&_.content-container.content-container-open_.content]:!bg-transparent',
              `h-auto lg:h-[56px] [&_.main-button]:!h-[56px] [&_.main-button]:!border-b-0 dropdown 
              [&_.popover-title]:leading-16 [&_.popover-title]:relative [&_.popover-title]:top-0 [&_.popover-title]:-left-1 
              [&.open_.popover-title]:text-paragraph-normal [&.open_.popover-title]:opacity-100 [&.open_.popover-title]:top-0 [&.open_.popover-title]:-left-1
              [&.open_.popover-title]:tracking-[0]
              [&_.input-group]:w-full [&_.content]:border-0
              `,
            )}
            placement={placement}
            mobileRelative={true}
            disableCloseOnClickOutside
          >
            {promoCode}
          </PopoverMenu>
          <div className="w-full hidden lg:block">{promoCode}</div>
        </div>
      </div>

      <div className="search-container flex [&>a]:!text-primary  ">
        <a
          href={bookingUrl.current ? bookingUrl.current.href : '#'}
          className={classNames(
            'btn px-12 w-[353px] lg:w-[210px] xxl:w-[260px] !h-[56px] !bg-highlight-sunshineYellow hover:!bg-highlight-sunshineYellow/75',
            searchClass,
            !formValid && '!opacity-25 pointer-events-none',
          )}
          aria-label={t('general.$opensanewwindow', { ariaLabel: t('booking.$checkAvailability') })}
          target="_blank"
        >
          {t('booking.$checkAvailability')}
        </a>
      </div>
    </>
  );
};

export default AvailabilityForm;
